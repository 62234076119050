import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import actions from '/store/actions';

const AlertSweet = ({ data, changeSweetAlert }) => {
  const closeSweetAlert = () => {
    changeSweetAlert({
      show: false,
    });
  };
  const onConfirmCustom = () => {
    data.onConfirm();
    closeSweetAlert();
  };
  const onCancelCustom = () => {
    data.onCancel();
    closeSweetAlert();
  };
  return (
    <SweetAlert
      {...data}
      onConfirm={data.onConfirm ? onConfirmCustom : closeSweetAlert}
      onCancel={data.onCancel ? onCancelCustom : closeSweetAlert}
      confirmBtnCssClass={'sweetalert-confirm'}
    />
  );
};

export default connect(state => state, actions)(AlertSweet);
